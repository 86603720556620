import React from "react"
import Layout from "../components/layout"
import Topicpath from "../components/topicpath"
import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight, faRedo } from "@fortawesome/free-solid-svg-icons"
import { faClock, faFolderOpen } from "@fortawesome/free-regular-svg-icons"

import { graphql, Link } from "gatsby"

import Img from "gatsby-image/withIEPolyfill"

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export const query = graphql`
    query($skip: Int!, $limit: Int!) {
        site {
            siteMetadata {
              title
            }
        }
        allContentfulBlogPost(sort: {fields: updatedAt, order: DESC}, skip: $skip, limit: $limit) {
            edges {
                node {
                    title
                    id
                    eyecatch {                        
                        fluid(maxWidth: 500) {
                          ...GatsbyContentfulFluid_withWebp
                        }
                        description
                    }
                    slug
                    publishDate
                    publishDateJp:publishDate(formatString: "YYYY年MM月DD日")
                    updatedAtJp:updatedAt(formatString: "YYYY年MM月DD日")
                    updatedAt
                    category {
                        categorySlug
                        category
                        id
                    }
                }
            }
        }
        bigcate:allContentfulCategory(filter: {navadd: {eq: "add"}}, sort: {fields: createdAt, order: DESC}) {
            edges {
                node {
                    category
                    id
                    categorySlug
                    blogpost {
                        title
                        slug
                    }
                    categoryS1
                    categoryS2
                    categoryS3
                    categoryS4
                }
            }
        }
        all:allContentfulCategory(sort: {fields: createdAt, order: ASC}) {
            edges {
                node {
                    category
                    id
                    categorySlug
                    blogpost {
                        id
                        title
                        slug
                    }
                    categoryS1
                    categoryS2
                    categoryS3
                    categoryS4
                }
            }
        }
        sitemap:file(relativePath: {eq: "sitemap1.jpg"}) {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
              original {
                src
                height
                width
              }
            }
        }
    }
`



export default ({ location, data, pageContext }) => {

    const bigcates = data.bigcate.edges
    const allcates = data.all.edges

    let bigcateCategoryS = []
    let allcateCategoryS = []
    let allcatesNewCategoryS = []
    let allcatesNew2CategoryS = []

    const roop = () => {
        const items = []

        items.push(
            bigcates.map(bigcateIndex => {
                bigcateCategoryS = [bigcateIndex.node.categoryS1, bigcateIndex.node.categoryS2, bigcateIndex.node.categoryS3, bigcateIndex.node.categoryS4]

                return <div className="wrapper">
                    <div className="bigcate">
                        <h2 className="bar l"><p className="en"><span>about </span><i>{bigcateIndex.node.categorySlug}</i></p><p>{bigcateIndex.node.category}</p></h2>
                        <p className="topTitle"><Link to={`/${bigcateIndex.node.categorySlug}/`}>{bigcateIndex.node.category} TOP<ChevronRightIcon /></Link></p>
                        <ul>
                            {bigcateIndex.node.blogpost.map(catlower => (
                                <li key={catlower.id}><Link to={`/undefined/${catlower.slug}/`}>
                                    {catlower.title}
                                    <ChevronRightIcon />
                                </Link></li>
                            ))}
                        </ul>
                    </div>

                    <ul className="smallcate">
                        {allcates.map(allcateIndex => {
                            allcateCategoryS = [allcateIndex.node.categoryS1, allcateIndex.node.categoryS2, allcateIndex.node.categoryS3, allcateIndex.node.categoryS4]

                            return bigcateCategoryS.map(bigcateCategorySIndex => (
                                bigcateCategorySIndex === allcateIndex.node.categorySlug && (<li key={allcateIndex.node.id}>
                                    <p className="topTitle"><Link to={`/${allcateIndex.node.categorySlug}/`}>{allcateIndex.node.category}<ChevronRightIcon /></Link></p>
                                    <ul>
                                        {allcateIndex.node.blogpost.map(catlower => (<>
                                            <li key={catlower.id}><Link to={`/undefined/${catlower.slug}/`}>
                                                {catlower.title}
                                                <ChevronRightIcon />
                                            </Link></li>
                                        </>))}
                                        {allcateCategoryS.map(allcateCategorySIndex => {
                                            if (allcateCategorySIndex !== "no-category") {
                                                return <li>
                                                    {allcates.map(allcatesNew => {
                                                        allcatesNewCategoryS = [allcatesNew.node.categoryS1, allcatesNew.node.categoryS2, allcatesNew.node.categoryS3, allcatesNew.node.categoryS4]

                                                        return allcateCategorySIndex === allcatesNew.node.categorySlug && (<>
                                                            <p className="topTitle"><Link to={`/${allcateCategorySIndex}/`}>
                                                                {`${allcatesNew.node.category}`}
                                                                <ChevronRightIcon />
                                                            </Link></p>
                                                            <ul>
                                                                {allcatesNew.node.blogpost.map(catlower => (<>
                                                                    <li key={catlower.id}><Link to={`/undefined/${catlower.slug}/`}>
                                                                        {catlower.title}
                                                                        <ChevronRightIcon />
                                                                    </Link></li>
                                                                </>))}
                                                                {allcatesNewCategoryS.map(allcatesNewCategorySIndex => {
                                                                    if (allcatesNewCategorySIndex !== "no-category") {
                                                                        return <li>
                                                                            {allcates.map(allcatesNew2 => {
                                                                                allcatesNew2CategoryS = [allcatesNew2.node.categoryS1, allcatesNew2.node.categoryS2, allcatesNew2.node.categoryS3, allcatesNew2.node.categoryS4]

                                                                                return allcatesNewCategorySIndex === allcatesNew2.node.categorySlug && (<>
                                                                                    <p className="topTitle"><Link to={`/${allcatesNewCategorySIndex}/`}>
                                                                                        {`${allcatesNew2.node.category}`}
                                                                                        <ChevronRightIcon />
                                                                                    </Link></p>
                                                                                    <ul>
                                                                                        {allcatesNew2.node.blogpost.map(catlower => (<>
                                                                                            <li key={catlower.id}><Link to={`/undefined/${catlower.slug}/`}>
                                                                                                {catlower.title}
                                                                                                <ChevronRightIcon />
                                                                                            </Link></li>
                                                                                        </>))}

                                                                                        {allcatesNew2CategoryS.map(allcatesNew2CategorySIndex => (
                                                                                            allcatesNew2CategorySIndex !== "no-category" && (<li>
                                                                                                {allcates.map(allcatesNew3 => (
                                                                                                    allcatesNew2CategorySIndex === allcatesNew3.node.categorySlug && (<>
                                                                                                        <p className="topTitle"><Link to={`/${allcatesNew2CategorySIndex}/`}>
                                                                                                            {`${allcatesNew3.node.category}`}
                                                                                                            <ChevronRightIcon />
                                                                                                        </Link></p>
                                                                                                        <ul>
                                                                                                            {allcatesNew3.node.blogpost.map(catlower => (<>
                                                                                                                <li key={catlower.id}><Link to={`/undefined/${catlower.slug}/`}>
                                                                                                                    {catlower.title}
                                                                                                                    <ChevronRightIcon />
                                                                                                                </Link></li>
                                                                                                            </>))}

                                                                                                        </ul>
                                                                                                    </>)
                                                                                                ))}
                                                                                            </li>)
                                                                                        ))}

                                                                                    </ul>
                                                                                </>)
                                                                            })}
                                                                        </li>
                                                                    }
                                                                    return false
                                                                })}
                                                            </ul>
                                                        </>)
                                                    })}
                                                </li>
                                            }
                                            return false
                                        })}
                                    </ul>
                                </li>)
                            ))
                        })}
                    </ul>
                </div>
            })
        )

        return <>{items}</>
    }

    return <>
        <SEO
            pagetitle="サイトマップ"
            pagedesc={`このサイト（Henri Morhange ${data.site.siteMetadata.title}）のページ構成を動的に一覧化しています。`}
            pagepath={location.pathname}
        />
        <Layout>
            <div className="eyecatch">
                <figure>
                    <Img fluid={data.sitemap.childImageSharp.fluid} style={{ height: "100%" }} alt="サイトマップ" />
                </figure>
                <h1 className="bar">サイトマップ</h1>
            </div>

            <Topicpath pageUrl={location.pathname} />

            <section className="content sitemap">
                <div className="wrapper">
                    <h2 className="bar l"><p>TOPページ</p></h2>
                    <p className="topTitle"><Link to={`/`}>TOP<ChevronRightIcon /></Link></p>
                </div>
                <div className="wrapper">
                    <h2 className="bar l"><p className="en"><i>about </i><span>this website</span></p><p>このサイトについて</p></h2>
                    <p className="topTitle"><Link to={`/about/`}>このサイトについて<ChevronRightIcon /></Link></p>
                </div>

                {roop()}
            </section>

            <section className="content bloglist">
                <div className="container">
                    <h2 className="bar l"><p className="en"><span>about </span><i>news</i></p><p>新着記事一覧</p></h2>

                    <div className="posts">
                        {data.allContentfulBlogPost.edges.map(blog => (
                            <article className="post" key={blog.node.id}>
                                <Link to={`/undefined/${blog.node.slug}/`}>
                                    <figure>
                                        <Img fluid={blog.node.eyecatch.fluid} style={{ height: "100%" }} alt={blog.node.eyecatch.description || `デフォルトのalt`} />
                                    </figure>
                                    <p className="title">{blog.node.title}</p>
                                </Link>
                                <time dateTime={blog.node.publishDate}><FontAwesomeIcon icon={faClock} />{blog.node.publishDateJp}</time>
                                <time dateTime={blog.node.updatedAt}><FontAwesomeIcon icon={faRedo} />{blog.node.updatedAtJp}</time>
                                {blog.node.category && (
                                    <div className="cat">
                                        <FontAwesomeIcon icon={faFolderOpen} />
                                        <ul>
                                            {blog.node.category.map(cat => (
                                                <li className={cat.categorySlug} key={cat.id}>
                                                    <Link to={`/${cat.categorySlug}/`}>
                                                        {cat.category}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                            </article>
                        ))}
                    </div>

                    <ul className="pagenation">
                        {!pageContext.isFirst && (
                            <li className="prev">
                                <Link to={
                                    pageContext.currentPage === 2
                                        ? `/sitemap/`
                                        : `/sitemap/${pageContext.currentPage - 1}/`
                                } rel="prev">
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                    <span>前のページ</span>
                                </Link>
                            </li>
                        )}
                        {!pageContext.isLast && (
                            <li className="next">
                                <Link to={`/sitemap/${pageContext.currentPage + 1}/`} rel="next">
                                    <span>次のページ</span>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </Link>
                            </li>
                        )}
                    </ul>

                </div>
            </section>
        </Layout>
    </>
}